import React from "react";
import { navigate } from "gatsby";
import { Button, ButtonGroup, ButtonToolbar, Col, Row } from "react-bootstrap";
import styles from "./ProgressButtons.module.scss";

const ProgressButtons = ({ }: ProgressButtonsProps) => {
  const create = () => {
    navigate("/");
  };

  const compare = () => {
    navigate("/compare/");
  };

  return (
    <>
      <Row id="progress-btns-row" className={styles.progressButtonsRow}>
          <Col xs={12}>
            <ButtonToolbar id="progress-btns-toolbar" className={styles.progressButtonsToolbar} aria-label="Toolbar with button groups">
              <ButtonGroup id="create-btn-grp" className={styles.createButton}  aria-label="First group">
                <Button onClick={create}>Create</Button>
              </ButtonGroup>
              <ButtonGroup id="compare-btn-grp" className={styles.compareButton} aria-label="Second group">
                <Button onClick={compare}>Compare</Button>
              </ButtonGroup>
              <ButtonGroup id="order-btn-grp" className={styles.orderButton} aria-label="Third group">
                <Button onClick={() => {}}>Order</Button>
              </ButtonGroup>
            </ButtonToolbar>
          </Col>      
      </Row>
    </>
  )
}

export default ProgressButtons
