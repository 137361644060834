import WordToNumbers from 'word-to-numbers';
import { 
    MATERIALS,
    UNITS,
    NEXT_CONDITIONS,
    DIGITS,
    MANUFACTURERS
} from 'src/utils/constants';

// tries to find any numbers. In absence of any numbers in the transcript, 
// it will look for numeric words and try and convert. 
// Otherwise, it will tell the user they need to search with numbers.
export function transcriptNumberMatcher(transcript) {
    var matches = transcript.replace(/[ ,.]/g, '').match(/\d+/);

    // checks if the transcript includes one of the digits
    const hasNumericalWords = DIGITS.some(element => transcript.includes(element));

    //if no numbers are found in transcribed text, convert using wordsToNumbers Node Package
    if (!!!matches)
    {
        const answer = WordToNumbers(transcript);
        matches = answer.match(/\d+/); 

        // tries to see if there is a number match after being transformed using wordToNumbers. 
        // 1. If a match hasn't been found, then it prompts the user to re-record and refresh the page. 
        // 2. If a match has been found, it continues to process the transcribed data and run the program
        if (!!!matches) 
        {
            return { 
                matchedData: answer, 
                matchedNumber: null, 
            };
        }
        else {
            const numberMatch = matches[0];
            
            return { 
                matchedData: answer, 
                matchedNumber: numberMatch,
            };
        }
    }
    else {
        // if numbers are found in transcribed text:
        // 1. Check that there aren't any numerical words leftover. 
        // If there are, convert the transcript using wordsToNumbers package
        // 2. If there aren't any numerical words, use the transcript as it is    
        if (!!hasNumericalWords) {
            const answer = WordToNumbers(transcript);
            matches = answer.match(/\d+/);
            const numberMatch = matches[0];

            return { 
                matchedData: answer, 
                matchedNumber: numberMatch, 
            };
        }
        else {
            const numberMatch = matches[0];

            return { 
                matchedData: transcript, 
                matchedNumber: numberMatch, 
            };
        }
    }
}

export function transcriptMaterialsMatcher(data) {
    // checks if the data include one of the materials
    const materialExists = MATERIALS.some(element => data.toLowerCase().includes(element.toLowerCase()));

    // if the transcript includes one of the materials listed, return true
    const materialsResult = MATERIALS.filter(element => {
        if (data.toLowerCase().includes(element.toLowerCase())) {
            return true;
        }
    })

    // if the transcript includes a number, return true
    const numberResult = UNITS.filter(element => {
        if (data.toLowerCase().includes(element.toLowerCase())) {
            return true;
        }
    })

    // if there was a material found, print out the result and the number found in the front end
    if (materialExists) {       
       // if the transcript has found the word next, then return that another item can be ordered
        if (NEXT_CONDITIONS.some(el => data.toLowerCase().includes(el.toLowerCase()))) {
            return materialsResult;
        }
        else {
            return materialsResult;
        }
    } else {
        // if no materials found
        return [];
    }
}

//checks if a manufacturer has been found in the transcript and return the manufacturer
export function transcriptManufacturerMatcher(data) {
    // checks if the data includes one of the manufacturers
    const manufacturerExists = MANUFACTURERS.some(element => data.toLowerCase().includes(element.toLowerCase()));

    // if the transcript includes one of the manufacturers listed, return true
    const manufacturersResult = MANUFACTURERS.filter(element => {
        if (data.toLowerCase().includes(element.toLowerCase())) {
            return true;
        }
    })

    // if the transcript includes a number, return true
    const numberResult = UNITS.filter(element => {
        if (data.toLowerCase().includes(element.toLowerCase())) {
            return true;
        }
    })

    // if there was a manufacturer found, print out the result and the number found in the front end
    if (manufacturerExists) {       
       // if the transcript has found the word "next", then return that another item can be ordered
        if (NEXT_CONDITIONS.some(el => data.toLowerCase().includes(el.toLowerCase()))) {
            return manufacturersResult;
        }
        else {
            return manufacturersResult;
        }
    } else {
        return [];
    }
}

export function transcriptVariationMatcher(data: string) {
    // We need to make sure that particular variation word is not held in existing lists and is not a number
    const combinedLists = [...MATERIALS, ...UNITS, ...DIGITS, ...MANUFACTURERS];
    return data.toLowerCase().split(" ").filter((word) => !combinedLists.includes(word) && !word.match(/\d+/));
}

